import { isFunction } from "lodash";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NgForm } from "@angular/forms";
import { ProfileService } from "../../services/profile.service";

@Component({
  selector: "app-tariff",
  templateUrl: "./tariff.component.html",
  styleUrls: ["./tariff.component.scss"],
})
export class TariffComponent implements OnInit {
  unitPricePence: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private profileService: ProfileService
  ) {}

  ngOnInit() {
    this.route.data.subscribe(({ unitPrice }) => {
      this.unitPricePence = unitPrice * 100;
    });
  }

  select(target) {
    isFunction(target.select) && target.select();
  }

  onSubmit(form: NgForm): void {
    if (!form.valid) {
      return;
    }

    const unitPrice = form.value.unitPricePence / 100;
    this.profileService
      .setProfileTariff(unitPrice)
      .then(() => this.router.navigate(["/calculation"]))
      .catch((err) => console.error(err));
  }
}
