import { keyBy, sumBy } from "lodash";
import { Injectable } from "@angular/core";
import configuration from "./configuration";
import * as appcalc from "appcalc";

export enum BillingCycle {
  day,
  week,
  month,
  year,
}

export interface Appliance {
  id: string;
  appcalc: string;
  name: string;
  sortOrder?: number;
  category: string;
  watts: number;
  usage: number;
  frequency: string;
  secondsPerUse?: number;
  extended?: boolean;
  abstracted?: boolean;
  exclude?: boolean;
  count?: number;
  light?: boolean;
  variableWattage?: number[];
  variableSquareMeter?: number[];
  squareMeters?: number;
  monthPerYear?: number;
}

export interface ApplianceCalculation {
  id: string;
  name: string;
  count: number;
  cost: number;
  usage: number;
  billingCycle: number;
}

export interface Calculation {
  count: number;
  usage: number;
  costStandingCharge: number;
  costIncVAT: number;
  costExVAT: number;
  costVAT: number;
  billingCycle: number;
  appliances: ApplianceCalculation[];
}

function defineAppliance({
  id,
  appcalc,
  name,
  sortOrder,
  category,
  watts,
  usage,
  frequency,
  secondsPerUse,
  abstracted,
  extended,
  exclude,
  light,
  variableWattage,
  variableSquareMeter,
  squareMeters,
  monthPerYear,
}): Appliance {
  return {
    id,
    appcalc,
    name,
    sortOrder,
    category,
    watts,
    usage,
    frequency,
    secondsPerUse,
    abstracted,
    extended,
    exclude,
    light,
    variableWattage,
    variableSquareMeter,
    squareMeters,
    monthPerYear,
  };
}

@Injectable()
export class ConfigurationService {
  configurationTitle: string = configuration.title;
  configurationDescription: string = configuration.description;
  configurationVersion: number = configuration.version;
  appliances: Appliance[] = configuration.appliances.map(defineAppliance);
  private forceGenericSize: boolean = configuration.forceGenericSize;
  private forceGenericUsage: boolean = configuration.forceGenericUsage;

  getAppliances(): Promise<Appliance[]> {
    return Promise.resolve(this.appliances.filter(({ exclude }) => !exclude));
  }

  getAppliance(id: string): Promise<Appliance> {
    return this.getAppliances().then((appliances) => {
      return appliances.find((appliance) => appliance.id === id);
    });
  }

  calculate(profile, billingCycle?: number): Promise<Calculation> {
    if (!BillingCycle[billingCycle]) {
      billingCycle = BillingCycle.year;
    }

    let days;
    switch (billingCycle) {
      case BillingCycle.day:
        days = 1;
        break;
      case BillingCycle.week:
        days = 7;
        break;
      case BillingCycle.month:
        days = 30;
        break;
      case BillingCycle.year:
        days = 365;
        break;
      default:
        days = 30;
        break;
    }

    return new Promise((resolve) => {
      // Filter final array of appliances to calculate against
      const profileAppliances = profile.appliances.filter((appliance) => {
        return (
          appliance.count &&
          this.appliances.some(({ exclude, id }) => {
            return !exclude && id === appliance.id;
          })
        );
      });

      // Build appliances configuration
      const configuration = keyBy(this.appliances, "id");

      // Calculate appliance usage
      const appliances = profileAppliances.map((appliance) =>
        calculateAppliance(
          profile,
          configuration,
          this.forceGenericSize,
          this.forceGenericUsage,
          appliance,
          days,
          billingCycle
        )
      );

      // Run aggregate calculations against appliances
      const {
        count,
        usage,
        costStandingCharge,
        costIncVAT,
        costExVAT,
        costVAT,
      } = calculateApplianceAggregates(profile, appliances, days);

      return resolve({
        count,
        usage,
        costStandingCharge,
        costIncVAT,
        costExVAT,
        costVAT,
        billingCycle,
        appliances,
      });
    });
  }
}

export function calculateAppliance(
  profile: any,
  configuration: any,
  forceGenericSize: boolean,
  forceGenericUsage: boolean,
  appliance: Appliance,
  days: number,
  billingCycle: number
): ApplianceCalculation {
  let cost = 0,
    usage = 0;

  const applianceConfig = configuration[appliance.id];
  let applianceWatts =
    (!forceGenericSize && appliance.watts) || applianceConfig.watts;
  const applianceUsage =
    (!forceGenericUsage && appliance.usage) || applianceConfig.usage;
  const applianceUsageFrequency =
    (!forceGenericUsage && appliance.frequency) || applianceConfig.frequency;
  const applianceSecondsPerUse = applianceConfig.secondsPerUse || 1;

  const applianceSquareMeters =
    (!forceGenericUsage && appliance.squareMeters) ||
    applianceConfig.squareMeters;

  const applianceMonthPerYear =
    (!forceGenericUsage && appliance.monthPerYear) ||
    applianceConfig.monthPerYear ||
    12;

  // Calculate total heating wattage for underfloor heating
  if (applianceSquareMeters) {
    applianceWatts = applianceSquareMeters * applianceWatts;
  }

  if (
    [applianceWatts, applianceUsage, applianceUsageFrequency].every(
      (val) => !!val
    )
  ) {
    // Calculate appliance usage
    usage += calculateUsingAlgorithm(
      applianceConfig.appcalc,
      1,
      applianceWatts,
      applianceUsage,
      applianceUsageFrequency,
      applianceSecondsPerUse,
      appliance.count,
      days,
      applianceMonthPerYear
    );

    // Calculate appliance cost
    cost += calculateUsingAlgorithm(
      applianceConfig.appcalc,
      profile.unitPrice,
      applianceWatts,
      applianceUsage,
      applianceUsageFrequency,
      applianceSecondsPerUse,
      appliance.count,
      days,
      applianceMonthPerYear
    );
  }

  return {
    id: applianceConfig.id,
    name: applianceConfig.name,
    count: appliance.count,
    usage,
    cost,
    billingCycle,
  };

  /**
   *
   * @param algorithm the name of the app calc algorithm
   * @param unitPrice rate per kwh
   * @param watts appliance wattage
   * @param usage usage in seconds (or number of time used for abstracted appliance)
   * @param frequency day | week | month | year
   * @param secondsPerUse 1 (or usage in seconds for abstracted appliance)
   * @param count number of appliances of this type
   * @param days billing cycle
   * @param monthPerYear number of months the appliance is used per year (for seasonal appliance like heating)
   * @return the cost of the appliance for the billing cycle
   */
  function calculateUsingAlgorithm(
    algorithm: string,
    unitPrice: number,
    watts: number,
    usage: number,
    frequency: string,
    secondsPerUse: number,
    count: number,
    days: number,
    monthPerYear: number = 12
  ): number {
    // the cost of the appliance per hour
    const calc = appcalc[algorithm](
      unitPrice,
      watts,
      usage * secondsPerUse,
      frequency
    );

    const seasonalCoefficient = monthPerYear / 12.0;

    return calc * count * (days * 24) * seasonalCoefficient;
  }
}

function calculateApplianceAggregates(
  { standingCharge, vat }: any,
  appliances: ApplianceCalculation[],
  days: number
): any {
  const costStandingCharge = standingCharge * days;
  let { count, usage, cost } = sumTotalCostUsage(appliances);

  return {
    count,
    usage,
    costStandingCharge,
    ...calculateVATBreakdown(cost + costStandingCharge, vat),
  };

  function sumTotalCostUsage(appliances: ApplianceCalculation[]) {
    return appliances.reduce(
      (memo, appliance) => {
        if (!appliance.count) return memo;

        memo.count += appliance.count;
        memo.usage += appliance.usage;
        memo.cost += appliance.cost;
        return memo;
      },
      { count: 0, usage: 0, cost: 0 }
    );
  }

  function calculateVATBreakdown(cost: number, vat: number) {
    return {
      costExVAT: cost,
      costIncVAT: cost * (1 + vat),
      costVAT: cost * vat,
    };
  }
}
