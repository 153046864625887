<form class="full-height d-flex flex-column align-items bg-inverse" #$form="ngForm" (ngSubmit)="onSubmit($form)" novalidate>
  <section class="container scrollable">
    <a routerLink="/calculation" class="text-white"><i class="edf-icon edf-icon-3x edf-icon-close"></i></a>
    <h1 class="display-1 text-center text-white mt-3">Update your unit price</h1>
    <aside class="vector-responsive m-3" [inlineSVG]="'/assets/svg/unit-price.svg'"></aside>
    <div class="form-group mx-3 mb-3">
      <div class="input-group">
        <input type="number" name="unitPricePence" class="form-control text-right text-white display-1" [(ngModel)]="unitPricePence" (click)="select($event.target)" required />
        <span class="unit-price-label [ input-group-addon text-white ]">pence / kWh</span>
      </div>
    </div>
    <p class="text-white mx-3">Our calculations are based on an average of the EDF Energy Standard Variable Rate unit price.</p>
    <p class="text-white mx-3">By adding your own unit price here, you’ll make the calculation more accurate.</p>
    <p class="text-white mx-3">You should be able find your unit price on your latest electricity bill – it’s measured in pence per kilowatt-hour (kWh).</p>
  </section>
  <section class="btn-wrapper">
    <button type="submit" class="btn btn-lg btn-baseline btn-block btn-primary" [disabled]="!$form.valid">Update unit price</button>
  </section>
</form>
