<article class="full-height d-flex flex-column align-items bg-white">
  <header class="text-center bg-primary">
    <div
      class="edf-logo"
      aria-label="EDF Logo"
      [inlineSVG]="'/assets/svg/edf.svg'"
    ></div>
  </header>
  <section class="scrollable">
    <header class="bg-muted mb-5">
      <div class="text-center text-md-left container [ py-3 py-md-5 ]">
        <h1 class="display-1">EDF Energy<sup>BETA</sup> Trial</h1>
        <p class="mt-3 mb-0">
          Before you start, we just need a few details from your latest
          electricity bill.
        </p>
      </div>
    </header>
    <div class="container">
      <section class="summary d-flex justify-content-between mb-3">
        <div class="col-12 col-sm-6 col-md-3 summary__image">
          <div
            class="vector-responsive"
            aria-label="Data Capture"
            [inlineSVG]="'/assets/svg/data-capture/intro.svg'"
          ></div>
        </div>
        <div class="col-12 col-md-7 summary__text">
          <p class="mt-2">We need:</p>
          <ul class="mt-3">
            <li>Total electricity cost (measured in £)</li>
            <li>Electricity units used (measured in kWh)</li>
            <li>Unit rate price (pence per kWh)</li>
            <li>Period of time the bill covers (in days)</li>
          </ul>
          <p class="mt-1">
            If you’re on a Dual Fuel tariff (gas and electricity), we need to
            know about the electricity component only. All details you provide
            are stored securely and anonymously.
          </p>
        </div>
      </section>
    </div>
  </section>
  <section class="btn-wrapper">
    <button
      class="[ btn btn-lg btn-baseline btn-block btn-secondary ] mt-3 w100"
      (click)="next()"
      type="submit"
    >
      Next
    </button>
  </section>
</article>
