import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BillingCycle, Calculation, ConfigurationService } from '../services/configuration.service';
import { Profile, ProfileService } from '../services/profile.service';

@Injectable()
export class CalculationResolver implements Resolve<Calculation> {

  constructor(
    private configurationService: ConfigurationService,
    private profileService: ProfileService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Calculation> {
    return this.profileService.getProfile()
      .then((profile: Profile) => {
        const billingCycle = BillingCycle[route.queryParams.billingCycle];
        return this.configurationService.calculate(profile, parseInt(billingCycle));
      });
  }

}
