import { hoursToSeconds } from './utils/'
import forOtherAppliance from './forOtherAppliance'

/**
 *  Calculate energy consumption for refrigerators & freezers
 *  @param {number} ratePerKWh Rate per KWh
 *  @param {number} watts Appliance Wattage
 *  @returns {number} Cost per Hour
 *  @alias module:appcalc.forRefrigeration
 */
export default function forRefrigeration(
  ratePerKWh: number,
  watts: number,
): number {
  return forOtherAppliance(ratePerKWh, watts * 0.2, hoursToSeconds(14))
}
