<article class="full-height scrollable align-items bg-white">
  <div class="[ mb-0 full-height ] [ d-flex flex-column ] align-items">
    <header class="text-center bg-primary">
      <div class="edf-logo" aria-label="EDF Logo" [inlineSVG]="'/assets/svg/edf.svg'"></div>
    </header>
    <section>
      <header class="bg-muted mb-5">
        <div class="text-center text-md-left container [ py-3 py-md-5 ]">
          <h1 class="display-1">Your bill details</h1>
          <p class="mt-3 mb-0">Thank you for providing those details. Please review and confirm they are correct.</p>
        </div>
      </header>
      <section class="zoom container">
        <div class="summary d-flex">
          <p>Total electricity charge:</p>
          <div class="text-right">£{{lastBillCharge}}</div>
        </div>
        <div class="summary d-flex mt-3">
          <p>Electricity units:</p>
          <div class="text-right">{{lastBillKWH}}kWh</div>
        </div>
        <div class="summary d-flex mt-3">
          <p>Unit rate:</p>
          <div class="text-right">{{lastBillUnitRate}}p</div>
        </div>
        <div class="summary d-flex mt-3">
          <p>Billing period:</p>
          <div class="text-right">{{lastBillPeriod}} days</div>
        </div>
      </section>
    </section>
    <section class="btn-wrapper">
      <div class="d-flex">
        <button class="[ btn btn-lg btn-baseline btn-primary ] w100 text-white dc-previous" type="button" (click)="previous()">
          <i class="edf-icon edf-icon-chevron-left"></i>
          <span>Back</span>
        </button>
        <button class="[ btn btn-lg btn-baseline btn-primary ] w100 dc-next" (click)="next()">
          <span>Next</span>
          <i class="edf-icon edf-icon-chevron-right"></i>
        </button>
      </div>
    </section>
  </div>
</article>
