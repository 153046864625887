<div class="row">
  <div class="col-8 col-md-10">
    <p class="mb-2 zoom">{{ name }}</p>
  </div>
  <div class="col-4 col-md-2 text-right">
    <p class="mb-2 zoom calc-price">{{ cost | currency : "GBP" : true }}</p>
  </div>
</div>
<div class="row">
  <div class="col-8 col-md-10">
    <div class="progress">
      <div
        class="progress__bar"
        role="progressbar"
        [@draw]
        [ngClass]="{
          'bg-danger': relativeCost >= 75,
          'bg-warning': relativeCost < 75 && relativeCost >= 25,
          'bg-success': relativeCost < 25
        }"
        [ngStyle]="{ 'width.%': relativeCost }"
        [attr.aria-valuenow]="relativeCost"
        [attr.aria-valuemin]="0"
        [attr.aria-valuemax]="100"
      ></div>
    </div>
  </div>
  <div class="col-4 col-md-2 text-right">
    <p class="mb-0 text-muted">{{ usage | number : "1.0-0" }}kWh</p>
  </div>
</div>
