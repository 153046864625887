import { isFunction } from "lodash";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ProfileService } from "../../../services/profile.service";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-electricity-period",
  templateUrl: "./electricity-period.component.html",
  styleUrls: ["./electricity-period.component.scss"],
})
export class ElectricityPeriodComponent implements OnInit {
  lastBillPeriod: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private profileService: ProfileService
  ) {}

  ngOnInit() {
    this.route.data.subscribe(({ lastBill }) => {
      this.lastBillPeriod = lastBill.lastBillPeriod;
    });
  }

  previous() {
    this.router.navigate(["../../data-capture/electricity-ppk"], {
      relativeTo: this.route,
    });
  }

  select(target) {
    isFunction(target.select) && target.select();
  }

  onSubmit(form: NgForm): void {
    if (!form.valid) {
      return;
    }

    const lastBillPeriod = form.value.lastBillPeriod;
    this.profileService
      .setLastBillPeriod(lastBillPeriod)
      .then(() =>
        this.router.navigate(["../../data-capture/electricity-confirm"], {
          relativeTo: this.route,
        })
      )
      .catch((err) => console.error(err));
  }
}
