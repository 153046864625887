import { Injectable } from "@angular/core";
import { assignIn } from "lodash";

import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs";

import { Calculation } from "./configuration.service";

export interface ProfileAppliance {
  id: string;
  count: number;
  usage?: number;
  frequency?: string;
  watts?: number;
  squareMeters?: number;
  monthPerYear?: number;
}

export interface Profile {
  vat: number;
  unitPrice: number;
  standingCharge: number;
  appliances: ProfileAppliance[];
}

export interface LastBill {
  lastBillCharge: number;
  lastBillKWH: number;
  lastBillUnitRate: number;
  lastBillPeriod: number;
}

@Injectable()
export class ProfileService {
  private standardVariableRate: number = 0.2408;
  private basicStandingCharge: number = 0.5419;
  private energyUKVATRate: number = 0.05;
  private local: Profile;
  private lastBill: LastBill;
  private resultSubmitted: boolean = false;

  constructor(private http: HttpClient) {
    this.local = {
      vat: this.energyUKVATRate,
      unitPrice: this.standardVariableRate,
      standingCharge: this.basicStandingCharge,
      appliances: [],
    };
    this.lastBill = {
      lastBillCharge: 0.0,
      lastBillKWH: 0.0,
      lastBillUnitRate: 0.0,
      lastBillPeriod: 0.0,
    };
  }

  getProfile(): Promise<Profile> {
    return Promise.resolve(this.local);
  }

  getLastBill(): Promise<LastBill> {
    return Promise.resolve(this.lastBill);
  }

  getProfileTariff(): Promise<number> {
    return Promise.resolve(this.local.unitPrice);
  }

  setProfileTariff(unitPrice: number): Promise<Profile> {
    this.local.unitPrice = unitPrice;
    return Promise.resolve(this.local);
  }

  getLastBillCharge(): Promise<number> {
    return Promise.resolve(this.lastBill.lastBillCharge);
  }

  setLastBillCharge(lastBillCharge: number): Promise<LastBill> {
    this.lastBill.lastBillCharge = lastBillCharge;
    return Promise.resolve(this.lastBill);
  }

  getLastBillKWH(): Promise<number> {
    return Promise.resolve(this.lastBill.lastBillKWH);
  }

  setLastBillKWH(lastBillKWH: number): Promise<LastBill> {
    this.lastBill.lastBillKWH = lastBillKWH;
    return Promise.resolve(this.lastBill);
  }

  getLastBillUnitRate(): Promise<number> {
    return Promise.resolve(this.lastBill.lastBillUnitRate);
  }

  setLastBillUnitRate(lastBillUnitRate: number): Promise<LastBill> {
    this.lastBill.lastBillUnitRate = lastBillUnitRate;
    return Promise.resolve(this.lastBill);
  }

  getLastBillPeriod(): Promise<number> {
    return Promise.resolve(this.lastBill.lastBillPeriod);
  }

  setLastBillPeriod(lastBillPeriod: number): Promise<LastBill> {
    this.lastBill.lastBillPeriod = lastBillPeriod;
    return Promise.resolve(this.lastBill);
  }

  getProfileVAT(): Promise<number> {
    return Promise.resolve(this.local.vat);
  }

  addAppliance(id: string, count: number = 1): Promise<ProfileAppliance> {
    const applianceInProfile = { id, count };

    this.local.appliances.push(applianceInProfile);
    return Promise.resolve(applianceInProfile);
  }

  getAppliance(id: string): Promise<ProfileAppliance> {
    return Promise.resolve(
      this.local.appliances.find((appliance) => appliance.id === id)
    );
  }

  removeAppliance(id: string): Promise<void> {
    return this.getAppliance(id).then((applianceInProfile) => {
      if (applianceInProfile) applianceInProfile.count = 0;
    });
  }

  setApplianceModel(
    id: string,
    { count, usage, frequency, watts, monthPerYear, squareMeters }: any
  ): Promise<ProfileAppliance> {
    return this.getAppliance(id).then(
      (applianceInProfile: ProfileAppliance) => {
        if (!applianceInProfile) {
          throw new Error("Missing appliance in profile");
        }

        return assignIn(applianceInProfile, {
          count,
          usage,
          frequency,
          watts,
          monthPerYear,
          squareMeters,
        });
      }
    );
  }

  setApplianceCount(id: string, count: number): Promise<ProfileAppliance> {
    return this.setApplianceModel(id, { count });
  }

  getResultSubmitted(): Promise<boolean> {
    return Promise.resolve(this.resultSubmitted);
  }

  setResultSubmitted(resultSubmitted: boolean): Promise<boolean> {
    this.resultSubmitted = resultSubmitted;
    return Promise.resolve(this.resultSubmitted);
  }

  submitResult(
    calculation: Calculation,
    submitResultURL: string
  ): Observable<Response> {
    const bodyString = JSON.stringify({
      submissionDate: new Date().toLocaleString(),
      submitResultURL: submitResultURL,
      local: this.local,
      lastBill: this.lastBill,
      calculation: calculation,
    });

    return this.http.post<Response>(submitResultURL, bodyString);
  }
}
