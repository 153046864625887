import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ProfileService } from "../../../services/profile.service";

@Component({
  selector: "app-electricity-confirm",
  templateUrl: "./electricity-confirm.component.html",
  styleUrls: ["./electricity-confirm.component.scss"],
})
export class ElectricityConfirmComponent implements OnInit {
  lastBillCharge: number;
  lastBillKWH: number;
  lastBillUnitRate: number;
  lastBillPeriod: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private profileService: ProfileService
  ) {}

  ngOnInit() {
    this.route.data.subscribe(({ lastBill }) => {
      this.lastBillCharge = lastBill.lastBillCharge;
      this.lastBillKWH = lastBill.lastBillKWH;
      this.lastBillUnitRate = lastBill.lastBillUnitRate;
      this.lastBillPeriod = lastBill.lastBillPeriod;
    });
  }

  previous() {
    this.router.navigate(["../../data-capture/electricity-period"], {
      relativeTo: this.route,
    });
  }

  next() {
    this.router.navigate(["../../appliances"], {
      relativeTo: this.route,
    });
  }
}
