<article class="pb-0 full-height d-flex flex-column align-content-stretch">
  <header class="text-center">
    <div
      class="edf-logo"
      aria-label="EDF Logo"
      [inlineSVG]="'/assets/svg/edf.svg'"
    ></div>
  </header>
  <section class="intro [ d-flex flex-column justify-content-center ]">
    <div
      *ngIf="showLogo"
      class="ace-logo text-center"
      aria-label="ACE Logo"
      [inlineSVG]="'/assets/svg/ace.svg'"
    ></div>
    <p
      class="intro__description display-1 mt-3 mb-0 px-5 text-center text-white"
    >
      How much are your electrical appliances costing you?
    </p>
    <aside class="circle-container [ text-center ]">
      <ul class="circle-container-inner [ list-unstyled mb-0 ]">
        <li class="deg deg-0 [ text-white ]">
          <i class="edf-icon edf-icon-4x edf-icon-food-processor"></i>
        </li>
        <li class="deg deg-36 [ text-white ]">
          <i class="edf-icon edf-icon-4x edf-icon-colour-lcd-tv"></i>
        </li>
        <li class="deg deg-72 [ text-white ]">
          <i class="edf-icon edf-icon-4x edf-icon-games-console"></i>
        </li>
        <li class="deg deg-108 [ text-white ]">
          <i class="edf-icon edf-icon-4x edf-icon-oil-filled-heater"></i>
        </li>
        <li class="deg deg-144 [ text-white ]">
          <i class="edf-icon edf-icon-4x edf-icon-hair-dryer"></i>
        </li>
        <li class="deg deg-180 [ text-white ]">
          <i class="edf-icon edf-icon-4x edf-icon-extractor-fan"></i>
        </li>
        <li class="deg deg-216 [ text-white ]">
          <i class="edf-icon edf-icon-4x edf-icon-slow-cooker"></i>
        </li>
        <li class="deg deg-252 [ text-white ]">
          <i class="edf-icon edf-icon-4x edf-icon-washing-machine"></i>
        </li>
        <li class="deg deg-288 [ text-white ]">
          <i class="edf-icon edf-icon-4x edf-icon-kettle"></i>
        </li>
        <li class="deg deg-324 [ text-white ]">
          <i class="edf-icon edf-icon-4x edf-icon-microwave"></i>
        </li>
      </ul>
    </aside>
  </section>
  <section class="mt-auto">
    <a
      routerLink="{{ getStartedUrl }}"
      class="btn btn-lg btn-baseline btn-block btn-secondary"
    >
      <span>Get started</span>
      <i class="edf-icon edf-icon-chevron-right"></i>
    </a>
  </section>
</article>
