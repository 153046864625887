export enum Frequency {
  hour, day, week, month, year,
}

export default function(frequency: string): number {

  switch (Frequency[frequency]) {
    case Frequency.hour:
      return 1
    case Frequency.day:
      return 24
    case Frequency.week:
      return 7 * 24
    case Frequency.month:
      return 30 * 24
    case Frequency.year:
      return 365 * 24
    default: {

      // Format list of accepted strings
      const keys = Object.keys(Frequency)
      const accepted = keys.slice(keys.length / 2).join(', ')

      // Throw unrecognised error
      throw new Error(`Frequency '${frequency}' not recognised, use one of: ${accepted}`)
    }
  }
}
