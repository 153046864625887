export default {
  title: "Appliance and icon mapping",
  description:
    'Configuration collaborated in Tim\'s review. See https://docs.google.com/spreadsheets/d/1CuYkf6VYZ6sS0NJ_9vfe6MVaX2A6ztdkQjTj8fMMD10/edit.\n\n- (v2) set all heating appliances to air conditioning algorithm\n- (v3) added "Always On" setting to respective appliances\n- (v4) set discrete timings for respective appliances\n- (v5) updated naming of appliances\n- (v6) added categories to appliances\n- (v7) dropped heating appliances',
  forceGenericSize: false,
  forceGenericUsage: false,
  appliances: [
    {
      id: "RGVlcCBGYXQgRnJ5ZXI1=",
      appcalc: "forOtherAppliance",
      name: "Heat pump",
      watts: 3000,
      variableWattage: [2000, 3000, 4000, 5000, 6000, 7000],
      monthPerYear: 4,
      usage: 25200,
      frequency: "day",
      count: 0,
      exclude: false,
      sortOrder: 1,
      category: "Electric heating",
    },
    {
      id: "RGVlcCBGYXQgRnJ5ZXI3=",
      appcalc: "forOtherAppliance",
      name: "Plug-in heater",
      watts: 2000,
      variableWattage: [500, 800, 1000, 1200, 1600, 2000, 2500, 3000],
      monthPerYear: 4,
      usage: 14400,
      frequency: "day",
      count: 0,
      exclude: false,
      sortOrder: 1,
      category: "Electric heating",
    },
    {
      id: "RGVlcCBGYXQgRnJ5ZXI4=",
      appcalc: "forOtherAppliance",
      name: "Storage heater",
      watts: 2500,
      variableWattage: [1700, 2000, 2500, 3000, 3400],
      monthPerYear: 4,
      usage: 25200,
      frequency: "day",
      count: 0,
      exclude: false,
      sortOrder: 1,
      category: "Electric heating",
    },
    {
      id: "RGVlcCBGYXQgRnJ5ZXI2=",
      appcalc: "forOtherAppliance",
      name: "Underfloor heating",
      watts: 150,
      usage: 14400,
      squareMeters: 20,
      variableSquareMeter: [5, 10, 15, 20, 25, 30],
      monthPerYear: 4,
      frequency: "day",
      count: 0,
      exclude: false,
      sortOrder: 4,
      category: "Electric heating",
    },
    {
      id: "RGVlcCBGYXQgRnJ5ZXI=",
      appcalc: "forOtherAppliance",
      name: "Deep fat fryer",
      watts: 1500,
      usage: 2400,
      frequency: "day",
      count: 0,
      exclude: false,
      sortOrder: 3,
      category: "Food & drink",
    },
    {
      id: "Q29va2VyIEhvb2QgRXh0cmFjdG9yIEZhbg==",
      appcalc: "forOtherAppliance",
      name: "Cooker hood extractor fan",
      watts: 75,
      usage: 5400,
      frequency: "day",
      count: 1,
      exclude: false,
      sortOrder: 2,
      category: "Food & drink",
    },
    {
      id: "RWxlY3RyaWMgb3Zlbg==",
      appcalc: "forOtherAppliance",
      name: "Electric oven",
      watts: 1695,
      usage: 5400,
      frequency: "day",
      count: 1,
      exclude: false,
      sortOrder: 5,
      category: "Food & drink",
    },
    {
      id: "RWxlY3RyaWMgSG9i",
      appcalc: "forOtherAppliance",
      name: "Electric hob",
      watts: 2500,
      usage: 3600,
      frequency: "day",
      count: 0,
      exclude: false,
      sortOrder: 4,
      category: "Food & drink",
    },
    {
      id: "Rm9vZCBwcm9jZXNzb3I=",
      appcalc: "forOtherAppliance",
      name: "Food processor",
      watts: 340,
      usage: 600,
      frequency: "day",
      count: 0,
      exclude: false,
      sortOrder: 6,
      category: "Food & drink",
    },
    {
      id: "SGFuZCBibGVuZGVy",
      appcalc: "forOtherAppliance",
      name: "Hand blender",
      watts: 175,
      usage: 720,
      frequency: "day",
      count: 0,
      exclude: false,
      sortOrder: 10,
      category: "Food & drink",
    },
    {
      id: "S2V0dGxl",
      appcalc: "forOtherAppliance",
      name: "Kettle",
      watts: 2000,
      usage: "5",
      frequency: "day",
      count: 1,
      exclude: false,
      abstracted: true,
      extended: false,
      secondsPerUse: "120",
      sortOrder: 11,
      category: "Food & drink",
    },
    {
      id: "TWljcm93YXZl",
      appcalc: "forOtherAppliance",
      name: "Microwave",
      watts: 700,
      usage: 600,
      frequency: "day",
      count: 1,
      exclude: false,
      sortOrder: 12,
      category: "Food & drink",
    },
    {
      id: "VG9hc3Rlcg==",
      appcalc: "forOtherAppliance",
      name: "Toaster",
      watts: 1125,
      usage: "2",
      frequency: "day",
      count: 1,
      exclude: false,
      abstracted: true,
      extended: false,
      secondsPerUse: "300",
      sortOrder: 14,
      category: "Food & drink",
    },
    {
      id: "U2xvdyBjb29rZXIgKG9uZSBwb3QgY29va2VyKQ==",
      appcalc: "forOtherAppliance",
      name: "Slow cooker",
      watts: 130,
      usage: 9000,
      frequency: "day",
      count: 0,
      exclude: false,
      sortOrder: 13,
      category: "Food & drink",
    },
    {
      id: "RGlzaHdhc2hlcg==",
      appcalc: "forOtherAppliance",
      name: "Dishwasher",
      watts: 900,
      usage: "5",
      frequency: "week",
      count: 0,
      exclude: false,
      abstracted: true,
      extended: false,
      secondsPerUse: "3600",
      sortOrder: 1,
      category: "Cleaning & laundry",
    },
    {
      id: "RnJpZGdlIGZyZWV6ZXI=",
      appcalc: "forRefrigeration",
      name: "Fridge / freezer",
      watts: 290,
      usage: 86400,
      frequency: "day",
      count: 1,
      exclude: false,
      extended: true,
      sortOrder: 7,
      category: "Food & drink",
    },
    {
      id: "SXJvbg==",
      appcalc: "forOtherAppliance",
      name: "Iron",
      watts: 1500,
      usage: 3600,
      frequency: "day",
      count: 1,
      exclude: false,
      sortOrder: 2,
      category: "Cleaning & laundry",
    },
    {
      id: "V2FzaGluZyBtYWNoaW5l",
      appcalc: "forOtherAppliance",
      name: "Washing machine",
      watts: 650,
      usage: "5",
      frequency: "week",
      count: 1,
      exclude: false,
      abstracted: true,
      extended: false,
      secondsPerUse: "5400",
      sortOrder: 6,
      category: "Cleaning & laundry",
    },
    {
      id: "VHVtYmxlIGRyaWVy",
      appcalc: "forOtherAppliance",
      name: "Tumble dryer",
      watts: 1500,
      usage: 7200,
      frequency: "day",
      count: 0,
      exclude: false,
      sortOrder: 3,
      category: "Cleaning & laundry",
    },
    {
      id: "RGVza3RvcCBjb21wdXRlciB3LyBtb25pdG9y",
      appcalc: "forOtherAppliance",
      name: "Desktop computer",
      watts: 160,
      usage: 7200,
      frequency: "day",
      count: 0,
      exclude: false,
      sortOrder: 2,
      category: "Computers & accessories",
    },
    {
      id: "TGFwdG9wIGNvbXB1dGVy",
      appcalc: "forOtherAppliance",
      name: "Laptop computer",
      watts: 20,
      usage: 7200,
      frequency: "day",
      count: 1,
      exclude: false,
      sortOrder: 3,
      category: "Computers & accessories",
    },
    {
      id: "R2FtZXMgY29uc29sZQ==",
      appcalc: "forOtherAppliance",
      name: "Games console",
      watts: 150,
      usage: 3600,
      frequency: "day",
      count: 1,
      exclude: false,
      sortOrder: 6,
      category: "Entertainment and devices",
    },
    {
      id: "UHJpbnRlciAvIHNjYW5uZXI=",
      appcalc: "forOtherAppliance",
      name: "Printer / scanner",
      watts: 20,
      usage: 600,
      frequency: "day",
      count: 0,
      exclude: false,
      sortOrder: 4,
      category: "Computers & accessories",
    },
    {
      id: "TW9kZW0gLyByb3V0ZXI=",
      appcalc: "forOtherAppliance",
      name: "Broadband modem (WiFi)",
      watts: 25,
      usage: 86400,
      frequency: "day",
      count: 1,
      exclude: false,
      extended: true,
      sortOrder: 1,
      category: "Computers & accessories",
    },
    {
      id: "TENEIG1vbml0b3I=",
      appcalc: "forOtherAppliance",
      name: "LCD monitor",
      watts: 50,
      usage: 3600,
      frequency: "day",
      count: 0,
      exclude: true,
    },
    {
      id: "TmV0Ym9vayAoMTAiIHNjcmVlbik=",
      appcalc: "forOtherAppliance",
      name: 'Netbook (10" screen)',
      watts: 10,
      usage: 3600,
      frequency: "day",
      count: 0,
      exclude: true,
    },
    {
      id: "TWFjYm9vaw==",
      appcalc: "forOtherAppliance",
      name: "Macbook",
      watts: 17.33,
      usage: 10800,
      frequency: "day",
      count: 1,
      exclude: true,
    },
    {
      id: "Q29sb3VyIHBsYXNtYSBUVg==",
      appcalc: "forOtherAppliance",
      name: "Colour TV (Plasma)",
      watts: 400,
      usage: 10800,
      frequency: "day",
      count: 0,
      exclude: false,
      sortOrder: 3,
      category: "Entertainment and devices",
    },
    {
      id: "Q29sb3VyIExDRCBUVg==",
      appcalc: "forOtherAppliance",
      name: "Colour TV (LCD/LED)",
      watts: 100,
      usage: 10800,
      frequency: "day",
      count: 1,
      exclude: false,
      sortOrder: 2,
      category: "Entertainment and devices",
    },
    {
      id: "UmFkaW8=",
      appcalc: "forOtherAppliance",
      name: "Digital radio",
      watts: 8,
      usage: 32400,
      frequency: "day",
      count: 0,
      exclude: false,
      sortOrder: 5,
      category: "Entertainment and devices",
    },
    {
      id: "RFZEIC8gQmx1cmF5IHBsYXllcg==",
      appcalc: "forOtherAppliance",
      name: "DVD / Bluray player",
      watts: 20,
      usage: 9000,
      frequency: "day",
      count: 0,
      exclude: false,
      sortOrder: 4,
      category: "Entertainment and devices",
    },
    {
      id: "RGlnaSBib3g=",
      appcalc: "forOtherAppliance",
      name: "TV set-top box (Sky, TiVo, BT etc.)",
      watts: 20,
      usage: 86400,
      frequency: "day",
      count: 1,
      exclude: false,
      extended: true,
      sortOrder: 8,
      category: "Entertainment and devices",
    },
    {
      id: "RGlnaSBib3g1=",
      appcalc: "forOtherAppliance",
      name: "Smart speakers",
      watts: 3,
      usage: 86400,
      frequency: "day",
      count: 0,
      exclude: false,
      extended: true,
      sortOrder: 9,
      category: "Entertainment and devices",
    },
    {
      id: "RGlnaXRhbCBwaG90byBmcmFtZQ==",
      appcalc: "forOtherAppliance",
      name: "Digital photo frame",
      watts: 12,
      usage: 86400,
      frequency: "day",
      count: 1,
      exclude: true,
    },
    {
      id: "RGlnaXRhbCAoY2FibGUpIFRWIGJveA==",
      appcalc: "forOtherAppliance",
      name: "Digital (cable) TV box",
      watts: 10,
      usage: 10800,
      frequency: "day",
      count: 0,
      exclude: true,
    },
    {
      id: "UG9ydGFibGUgYXVkaW8gZG9jaw==",
      appcalc: "forOtherAppliance",
      name: "Portable audio dock",
      watts: 11.5,
      usage: 3600,
      frequency: "day",
      count: 0,
      exclude: true,
    },
    {
      id: "TW9iaWxlIHBob25lIGNoYXJnZXI=",
      appcalc: "forOtherAppliance",
      name: "Phone / tablet charger",
      watts: 5,
      usage: 10800,
      frequency: "day",
      count: 1,
      exclude: false,
      sortOrder: 7,
      category: "Entertainment and devices",
    },
    {
      id: "RmlzaCB0YW5rIGMvdyBwdW1w",
      appcalc: "forAirConditioning",
      name: "Fish tank with pump",
      watts: 75,
      usage: 86400,
      frequency: "day",
      count: 0,
      exclude: false,
      extended: true,
      sortOrder: 1,
      category: "Large appliances and outdoor",
    },
    {
      id: "SGFuZCBoZWxkIHZhY3V1bSBjbGVhbmVy",
      appcalc: "forOtherAppliance",
      name: "Vacuum cleaner (handheld)",
      watts: 6,
      usage: 600,
      frequency: "day",
      count: 0,
      exclude: false,
      sortOrder: 5,
      category: "Cleaning & laundry",
    },
    {
      id: "QmFieSBtb25pdG9y",
      appcalc: "forOtherAppliance",
      name: "Baby monitor",
      watts: 5,
      usage: 43200,
      frequency: "day",
      count: 0,
      exclude: true,
    },
    {
      id: "VmFjdXVtIGNsZWFuZXI=",
      appcalc: "forOtherAppliance",
      name: "Vacuum cleaner",
      watts: 1050,
      usage: 3600,
      frequency: "day",
      count: 1,
      exclude: false,
      sortOrder: 4,
      category: "Cleaning & laundry",
    },
    {
      id: "RWxlY3RyaWMgc2V3aW5nIG1hY2hpbmU=",
      appcalc: "forOtherAppliance",
      name: "Electric sewing machine",
      watts: 100,
      usage: 1800,
      frequency: "day",
      count: 0,
      exclude: true,
    },
    {
      id: "RWxlY3RyaWMgc2hvd2Vy",
      appcalc: "forOtherAppliance",
      name: "Electric shower",
      watts: 8500,
      usage: 900,
      frequency: "day",
      count: 0,
      exclude: false,
      sortOrder: 2,
      category: "Bathroom & beauty",
    },
    {
      id: "RXh0cmFjdG9yIGZhbg==",
      appcalc: "forOtherAppliance",
      name: "Extractor fan",
      watts: 40,
      usage: 1800,
      frequency: "day",
      count: 1,
      exclude: false,
      sortOrder: 3,
      category: "Bathroom & beauty",
    },
    {
      id: "SGFpciBjdXJsZXI=",
      appcalc: "forOtherAppliance",
      name: "Hair curlers",
      watts: 1200,
      usage: 600,
      frequency: "day",
      count: 0,
      exclude: false,
      sortOrder: 4,
      category: "Bathroom & beauty",
    },
    {
      id: "SGFpciBzdHJhaWdodGVuZXI=",
      appcalc: "forOtherAppliance",
      name: "Hair straighteners",
      watts: 1500,
      usage: 600,
      frequency: "day",
      count: 0,
      exclude: false,
      sortOrder: 6,
      category: "Bathroom & beauty",
    },
    {
      id: "SGFpciBkcnllcg==",
      appcalc: "forOtherAppliance",
      name: "Hair dryer",
      watts: 1500,
      usage: 600,
      frequency: "day",
      count: 1,
      exclude: false,
      sortOrder: 5,
      category: "Bathroom & beauty",
    },
    {
      id: "U3RhbmRhcmQgbGlnaHRidWxi",
      appcalc: "forOtherAppliance",
      name: "Standard lightbulb",
      watts: 25,
      usage: 14400,
      frequency: "day",
      count: 10,
      exclude: true,
    },
    {
      id: "TG93IGVuZXJneSBsaWdodGJ1bGI=",
      appcalc: "forOtherAppliance",
      name: "Low energy lightbulb",
      watts: 4.5,
      usage: 14400,
      frequency: "day",
      count: 0,
      exclude: true,
    },
    {
      appcalc: "forAirConditioning",
      name: "Night storage heater",
      watts: 2500,
      usage: 28800,
      frequency: "day",
      count: 0,
      id: "TmlnaHQgU3RvcmFnZSBIZWF0ZXI=",
      exclude: true,
      category: "Heating & hot water",
    },
    {
      appcalc: "forAirConditioning",
      name: "Halogen heater",
      usage: 10800,
      frequency: "day",
      count: 0,
      id: "SGFsb2dlbiBIZWF0ZXI=",
      exclude: true,
      watts: 1200,
      category: "Heating & hot water",
    },
    {
      appcalc: "forOtherAppliance",
      name: "Fan heater",
      usage: 10800,
      frequency: "day",
      count: 0,
      id: "RmFuIEhlYXRlcg==",
      exclude: true,
      watts: 2500,
      category: "Heating & hot water",
    },
    {
      appcalc: "forAirConditioning",
      name: "Convector heater",
      usage: 10800,
      frequency: "day",
      count: 0,
      id: "Q29udmVjdG9yIEhlYXRlcg==",
      exclude: true,
      watts: 1600,
      category: "Heating & hot water",
    },
    {
      appcalc: "forAirConditioning",
      name: "Oil filled heater",
      usage: 10800,
      frequency: "day",
      count: 0,
      id: "T2lsIEZpbGxlZCBIZWF0ZXI=",
      exclude: true,
      watts: 1600,
      category: "Heating & hot water",
    },
    {
      appcalc: "forAirConditioning",
      name: "Panel heater",
      usage: 10800,
      frequency: "day",
      count: 0,
      id: "UGFuZWwgSGVhdGVy",
      exclude: true,
      watts: 1600,
      category: "Heating & hot water",
    },
    {
      appcalc: "forAirConditioning",
      name: "Underfloor heating (5m²)",
      usage: 86400,
      frequency: "day",
      count: 0,
      id: "VW5kZXJmbG9vciBIZWF0aW5n",
      exclude: true,
      watts: 750,
      extended: true,
      category: "Heating & hot water",
    },
    {
      appcalc: "forAirConditioning",
      name: "Immersion heater",
      watts: 3000,
      usage: 10800,
      frequency: "day",
      count: 0,
      id: "SG90IFdhdGVyIEltbWVyc2lvbg==",
      exclude: true,
      category: "Heating & hot water",
    },
    {
      appcalc: "forOtherAppliance",
      name: "Colour TV (CRT)",
      watts: 150,
      usage: 10800,
      frequency: "day",
      count: 0,
      id: "T2xkIGJveCBUVg==",
      exclude: false,
      sortOrder: 1,
      category: "Entertainment and devices",
    },
    {
      appcalc: "forOtherAppliance",
      name: "Stereo CD/MP3",
      watts: 14,
      usage: null,
      frequency: "day",
      count: 0,
      id: "U3RlcmVvIENEL01QMw==",
      exclude: true,
    },
    {
      appcalc: "forOtherAppliance",
      name: "Portable stereo",
      watts: 8,
      usage: null,
      frequency: "day",
      count: 0,
      id: "UG9ydGFibGUgc3RlcmVv",
      exclude: true,
    },
    {
      appcalc: "forOtherAppliance",
      name: "Fish tank",
      watts: 4,
      usage: 604800,
      frequency: "week",
      count: 1,
      id: "RmlzaCB0YW5r",
      exclude: true,
    },
    {
      appcalc: "forOtherAppliance",
      name: "Sun Bed",
      usage: null,
      frequency: "week",
      count: 0,
      id: "U3VuIEJlZA==",
      exclude: true,
    },
    {
      appcalc: "forOtherAppliance",
      name: "Sauna",
      usage: null,
      frequency: "week",
      count: 0,
      id: "U2F1bmE=",
      exclude: true,
    },
    {
      appcalc: "forOtherAppliance",
      name: "Hot Tub",
      watts: 50,
      usage: 604800,
      frequency: "week",
      count: 1,
      id: "SG90IFR1Yg==",
      exclude: true,
    },
    {
      appcalc: "forOtherAppliance",
      name: "Swimming Pool",
      usage: null,
      frequency: "week",
      count: 0,
      id: "U3dpbW1pbmcgUG9vbA==",
      exclude: true,
    },
    {
      appcalc: "forAirConditioning",
      name: "Pond pump",
      usage: 86400,
      frequency: "day",
      count: 0,
      id: "UG9uZCBQdW1w",
      exclude: false,
      watts: 300,
      extended: true,
      sortOrder: 3,
      category: "Large appliances and outdoor",
    },
    {
      appcalc: "forOtherAppliance",
      name: "Hot tub / spa",
      usage: 86400,
      frequency: "day",
      count: 0,
      id: "UG9uZCBQdW1w2",
      exclude: false,
      watts: 4000,
      variableWattage: [2000, 3000, 4000, 5000, 6000, 7000, 8000],
      extended: true,
      sortOrder: 2,
      category: "Large appliances and outdoor",
    },
    {
      appcalc: "forOtherAppliance",
      name: "Reptile tank",
      usage: 86400,
      frequency: "day",
      count: 0,
      id: "UG9uZCBQdW1w1",
      exclude: false,
      watts: 50,
      extended: true,
      sortOrder: 4,
      category: "Large appliances and outdoor",
    },
    {
      appcalc: "forOtherAppliance",
      name: "Other Appliance #1",
      usage: null,
      frequency: "week",
      count: 0,
      id: "T3RoZXIgQXBwbGlhbmNlICMx",
      exclude: true,
    },
    {
      appcalc: "forOtherAppliance",
      name: "Other Appliance #2",
      usage: null,
      frequency: "week",
      count: 0,
      id: "T3RoZXIgQXBwbGlhbmNlICMy",
      exclude: true,
    },
    {
      appcalc: "forOtherAppliance",
      name: "Other Appliance #3",
      usage: null,
      frequency: "week",
      count: 0,
      id: "T3RoZXIgQXBwbGlhbmNlICMz",
      exclude: true,
    },
    {
      appcalc: "forOtherAppliance",
      name: "Coffee machine",
      watts: 500,
      usage: 1200,
      frequency: "day",
      count: 0,
      id: "Q29mZmVlIG1hY2hpbmU=",
      exclude: false,
      sortOrder: 1,
      category: "Food & drink",
    },
    {
      appcalc: "forOtherAppliance",
      name: "Electric grill",
      watts: 5808,
      usage: null,
      frequency: "week",
      count: 0,
      id: "RWxlY3RyaWMgZ3JpbGw=",
      exclude: true,
    },
    {
      appcalc: "forRefrigeration",
      name: "Fridge",
      usage: 86400,
      frequency: "day",
      id: "RnJpZGdl",
      exclude: false,
      watts: 250,
      extended: true,
      sortOrder: 9,
      category: "Food & drink",
    },
    {
      appcalc: "forRefrigeration",
      name: "Freezer",
      watts: 250,
      usage: 86400,
      frequency: "day",
      count: 1,
      id: "RnJlZXplcg==",
      exclude: false,
      extended: true,
      sortOrder: 8,
      category: "Food & drink",
    },
    {
      appcalc: "forOtherAppliance",
      name: "Hair straighteners",
      usage: null,
      frequency: "day",
      count: 0,
      id: "SGFpciBzdHJhaWdodGVuZXJz",
      exclude: true,
    },
    {
      appcalc: "forAirConditioning",
      name: "Electric heated towel rail",
      usage: 86400,
      frequency: "day",
      count: 0,
      id: "SGVhdGVkIHRvd2VsIHJhaWw=",
      exclude: false,
      watts: 150,
      extended: true,
      sortOrder: 1,
      category: "Bathroom & beauty",
    },
    {
      id: "TW9kZW0gLyByb3V0ZXI3=",
      appcalc: "forOtherAppliance",
      name: "Halogen lighting",
      watts: 42,
      usage: 18000,
      frequency: "day",
      count: 1,
      exclude: false,
      extended: true,
      light: true,
      sortOrder: 1,
      category: "Lighting",
    },
    {
      id: "TW9kZW0gLyByb3V0ZXI4=",
      appcalc: "forOtherAppliance",
      name: "Incandescent lighting",
      watts: 60,
      usage: 18000,
      frequency: "day",
      count: 1,
      exclude: false,
      extended: true,
      light: true,
      sortOrder: 2,
      category: "Lighting",
    },
    {
      id: "TW9kZW0gLyByb3V0ZXI5=",
      appcalc: "forOtherAppliance",
      name: "LED lighting",
      watts: 10,
      usage: 18000,
      frequency: "day",
      count: 1,
      exclude: false,
      extended: true,
      light: true,
      sortOrder: 3,
      category: "Lighting",
    },
    {
      id: "TW9kZW0gLyByb3V0ZXI1=",
      appcalc: "forOtherAppliance",
      name: "CCTV devices",
      watts: 30,
      usage: 86400,
      frequency: "day",
      count: 0,
      exclude: false,
      extended: true,
      sortOrder: 1,
      category: "Security",
    },
    {
      id: "TW9kZW0gLyByb3V0ZXI2=",
      appcalc: "forOtherAppliance",
      name: "Video doorbell",
      watts: 8,
      usage: 86400,
      frequency: "day",
      count: 0,
      exclude: false,
      extended: true,
      sortOrder: 2,
      category: "Security",
    },
  ],
  version: 7,
};
