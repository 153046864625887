import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'submit-result-thankyou',
  templateUrl: './submit-result-thankyou.component.html',
  styleUrls: ['./submit-result-thankyou.component.scss']
})
export class SubmitResultThankyou implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

  }

  backToResults() {
    this.router.navigate(['/calculation']);
  }

}
