import { assignIn } from "lodash";
import {
  Component,
  OnInit,
  Input,
  ElementRef,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  ProfileAppliance,
  ProfileService,
} from "../../services/profile.service";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";

@Component({
  selector: "app-appliance",
  templateUrl: "./appliance.component.html",
  styleUrls: ["./appliance.component.scss"],
  animations: [
    trigger("expand", [
      transition("void => *", [
        style({ "max-height": 0, opacity: 0 }),
        animate(
          ".3s ease-in-out",
          style({ "max-height": "100vh", opacity: 1 })
        ),
      ]),
      transition("* => void", [
        style({ "max-height": "100vh", opacity: 1 }),
        animate(".3s ease-in-out", style({ "max-height": 0, opacity: 0 })),
      ]),
    ]),
  ],
})
export class ApplianceComponent implements OnInit {
  @Input() id: string;
  @Input() name: string;
  @Input() usage: number;
  @Input() watts: number;
  @Input() variableWattage: number[];
  @Input() frequency: string;
  @Input() abstracted: boolean;
  @Input() extended: boolean;
  @Input() squareMeters: number;
  @Input() variableSquareMeter: number[];
  @Input() monthPerYear: number;
  @Input() light: boolean;
  @Output() askRemovalOfLightsSiblings = new EventEmitter<string>();
  appliance: ProfileAppliance;
  isSelected: boolean;
  isAdded: boolean;

  constructor(private el: ElementRef, private profileService: ProfileService) {}

  ngOnInit() {
    this.appliance = {
      id: this.id,
      count: 0,
      usage: this.usage,
      frequency: this.frequency,
      watts: this.watts,
      squareMeters: this.squareMeters,
      monthPerYear: this.monthPerYear,
    };

    this.profileService
      .getAppliance(this.id)
      .then((appliance: ProfileAppliance) => {
        assignIn(this.appliance, appliance);
        this.isAdded = !!this.appliance.count;
      });
  }

  add(data): Promise<void> {
    if (this.light) {
      this.askRemovalOfLightsSiblings.emit(this.id);
    }
    return this.profileService
      .getAppliance(this.id)
      .then((appliance: ProfileAppliance) => {
        if (appliance) {
          return appliance;
        }
        return this.profileService.addAppliance(this.id);
      })
      .then((appliance: ProfileAppliance) => {
        this.isAdded = data.count > 0;
        return this.profileService.setApplianceModel(appliance.id, data);
      })
      .then((appliance: ProfileAppliance) => {
        assignIn(this.appliance, appliance);
        this.close();
      });
  }

  remove($event?): Promise<void> {
    if ($event) {
      $event.stopPropagation();
    }

    return this.profileService.removeAppliance(this.id).then(() => {
      this.isAdded = false;
      this.close();
    });
  }

  open(): void {
    this.el.nativeElement.scrollIntoView({ behavior: "smooth" });

    this.isSelected = true;
  }

  close($event?): void {
    if (this.isSelected) {
      if ($event) {
        $event.stopPropagation();
      }
      this.isSelected = false;
    }
  }
}
