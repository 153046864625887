import { hoursToSeconds, secondsToHours, hoursInFrequency } from './utils/'

/**
 *  Calculate energy consumption for all other appliances
 *  @param {number} ratePerKWh Rate per KWh
 *  @param {number} watts Appliance Wattage
 *  @param {number} [usage=86400] Usage in Seconds
 *  @param {string} [frequency=day] Usage Frequency
 *  @returns {number} Cost per Hour
 *  @alias module:appcalc.forOtherAppliance
 */
export default function forOtherAppliance(
  ratePerKWh: number,
  watts: number,
  usage = hoursToSeconds(24),
  frequency = 'day',
): number {
  const kilowatts: number = watts / 1000
  const usageHours = secondsToHours(usage)
  const frequencyHours = hoursInFrequency(frequency)

  return ratePerKWh * ((kilowatts * usageHours) / frequencyHours)
}
