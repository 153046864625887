<article class="d-flex flex-column align-items bg-white">
  <form class="[ mb-0 full-height ] [ d-flex flex-column ] align-items" #$form="ngForm" (ngSubmit)="onSubmit($form)" novalidate>
    <header class="text-center bg-primary">
      <div class="edf-logo" aria-label="EDF Logo" [inlineSVG]="'/assets/svg/edf.svg'"></div>
    </header>
    <section class="scrollable">
      <header class="bg-muted mb-5">
        <div class="text-center text-md-left container [ py-3 py-md-5 ]">
          <h1 class="display-1">Electricity units (kWh)</h1>
          <p class="mt-3 mb-0">From your bill, please enter the value for “Electricity units used”</p>
        </div>
      </header>
      <div class="container">
        <section class="summary mb-3">
          <div class="summary__image">
            <img src="/assets/images/dc-eu-2.png" srcset="/assets/images/dc-eu-2@2x.png 2x, /assets/images/dc-eu-2@3x.png 3x" class="example-bill">
            <p class="note">Example bill</p>
          </div>
        </section>
      </div>
      <div class="container">
        <section class="d-flex mb-3">
          <input type="number" name="lastBillKWH" id="lastBillKWH" min="1" class="form-control display-2 text-default text-right w100"
                 [(ngModel)]="lastBillKWH" (click)="select($event.target)" required/>
          <div class="display-2 ml-2 mt-3 unit">kWh</div>
        </section>
      </div>
    </section>
    <section class="btn-wrapper">
      <div class="d-flex">
        <button class="[ btn btn-lg btn-baseline btn-primary ] w100 text-white dc-previous" type="button" (click)="previous()">
          <i class="edf-icon edf-icon-chevron-left"></i>
          <span>Back</span>
        </button>
        <button class="[ btn btn-lg btn-baseline btn-primary ] w100 dc-next" [disabled]="!$form.valid" type="submit">
          <span>Next</span>
          <i class="edf-icon edf-icon-chevron-right"></i>
        </button>
      </div>
    </section>
  </form>
</article>
