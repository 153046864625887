<article class="full-height d-flex flex-column align-items bg-white">
  <header class="text-center bg-primary">
    <div
      class="edf-logo"
      aria-label="EDF Logo"
      [inlineSVG]="'/assets/svg/edf.svg'"
    ></div>
  </header>
  <section class="scrollable">
    <header class="bg-muted mb-5">
      <div class="text-center text-md-left container [ py-3 py-md-5 ]">
        <h1 class="display-1">Add appliances</h1>
        <p class="mt-3 mb-0">Add all the appliances you have in your home</p>
      </div>
    </header>
    <div class="container">
      <ul class="categories [ list-unstyled ]">
        <li *ngFor="let category of categories">
          <h4 class="text-center text-md-left text-primary">{{ category }}</h4>
          <p class *ngIf="category == 'Lighting'" class="text-category-info">
            Select home's most common lighting type. An estimation based on
            average annual home usage will be provided accordingly.
          </p>
          <p
            class
            *ngIf="category == 'Electric heating'"
            class="text-category-info"
          >
            The cost of home heating will depend on how it’s used, the type of
            home it’s heating, weather and time of year. Answering the below
            will give an approximate annual cost.
          </p>
          <ul class="appliances [ list-unstyled ] mb-5">
            <li
              class="appliance"
              *ngFor="let appliance of appliances[category]"
            >
              <app-appliance
                [id]="appliance.id"
                [name]="appliance.name"
                [watts]="appliance.watts"
                [usage]="appliance.usage"
                [frequency]="appliance.frequency"
                [abstracted]="appliance.abstracted"
                [extended]="appliance.extended"
                [light]="appliance.light"
                (askRemovalOfLightsSiblings)="
                  onAskRemovalOfLightsSiblings($event)
                "
                [variableWattage]="appliance.variableWattage"
                [monthPerYear]="appliance.monthPerYear"
                [squareMeters]="appliance.squareMeters"
                [variableSquareMeter]="appliance.variableSquareMeter"
              ></app-appliance>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </section>
  <section class="btn-wrapper">
    <button
      type="button"
      class="btn btn-lg btn-baseline btn-block btn-secondary"
      (click)="startCalculating()"
    >
      Calculate
    </button>
  </section>
  <section id="modal" class="modal fade">
    <div class="modal-dialog mt-3" role="document">
      <div class="modal-content bg-muted">
        <div class="modal-body px-4">
          <h2 class="display-1 mt-3">Estimating...</h2>
          <p class="font-weight-bold mt-3 mr-4">
            The charges provided by this tool are for illustrative purposes
            only, your actual charges may differ depending upon your energy
            consumption, the characteristics of your appliances, your energy
            tariff, and other factors.  Please note, Time of Use consumption has
            not been applied to these illustrative charges.
          </p>
          <p class="font-weight-bold mt-3 mr-4">
            The following calculation is based on
          </p>
          <ul class="mt-3 mr-4 pl-3">
            <!--Hidden until Trials <li class="mt-3">an average of EDF Energy’s Standard tariff (which you can change later)</li>-->
            <li class="mt-3">
              An illustrative average power rating of common domestic appliances
              as per 2017 – for more accurate appliance usage, refer to the
              actual appliance
            </li>
            <li class="mt-3">
              The understanding that we haven’t included all the possible
              appliances you own
            </li>
            <li class="mt-3">
              An indicative price per unit of 25.28p and a daily standing charge
              of 56.90p both including VAT at 5%
            </li>
          </ul>
          <a
            routerLink="../calculation"
            class="[ btn btn-block btn-secondary btn-baseline ] mt-5"
            >I understand</a
          >
        </div>
      </div>
    </div>
  </section>
</article>
