<article
  class="card [ d-flex flex-column justify-content-center rounded my-3 ]"
  [ngClass]="{ added: isAdded && !isSelected }"
  (click)="open()"
>
  <section
    class="summary [ d-flex justify-content-between align-items-center p-2 ]"
  >
    <i
      class="appliance-icon [ edf-icon edf-icon-{{
        name | kebabCase
      }} edf-icon-4x ]"
    ></i>
    <header class="details">
      <h5>{{ name }}</h5>
      <p
        class="usage [ mb-0 ]"
        [ngClass]="{ 'text-muted': !isAdded || isSelected }"
        *ngIf="!monthPerYear"
      >
        Typical use:<br />{{ usage | usage: abstracted }} a {{ frequency }},
        {{ watts | number: "1.0-0" }}W
      </p>
      <p
        class="usage [ mb-0 ]"
        [ngClass]="{ 'text-muted': !isAdded || isSelected }"
        *ngIf="monthPerYear"
      >
        Typical use:<br />{{ monthPerYear | seasonalUsage }} a year,
        {{ watts | number: "1.0-0" }}W
      </p>
    </header>
    <button
      class="action [ btn btn-link mb-0 text-right text-default ]"
      (click)="isAdded && !isSelected ? remove($event) : close($event)"
    >
      <i class="edf-icon edf-icon-close edf-icon-2x" *ngIf="isSelected"></i>
      <span class="fa-stack" *ngIf="!isSelected">
        <i
          class="fa fa-circle fa-stack-2x"
          [ngClass]="{ 'text-success': isAdded }"
        ></i>
        <i
          class="fa fa-stack-1x text-white"
          [ngClass]="{ 'fa-plus': !isAdded, 'fa-check': isAdded }"
        ></i>
      </span>
    </button>
  </section>
  <app-appliance-detail
    [appliance]="appliance"
    [abstracted]="abstracted"
    [extended]="extended"
    [light]="light"
    *ngIf="isSelected"
    [added]="isAdded"
    (onSubmit)="add($event)"
    [@expand]
    [variableWattage]="variableWattage"
    [variableSquareMeter]="variableSquareMeter"
  ></app-appliance-detail>
</article>
