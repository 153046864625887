import forOtherAppliance from './forOtherAppliance'

/**
 *  Calculate energy consumption for air conditioning units
 *  @param {number} ratePerKWh Rate per KWh
 *  @param {number} watts Appliance Wattage
 *  @returns {number} Cost per Hour
 *  @alias module:appcalc.forAirConditioning
 */
export default function forAirConditioning(
  ratePerKWh: number,
  watts: number,
): number {
  const calculatedWattage: number = watts * Math.pow(0.8, 2) + (watts * 0.2)
  return forOtherAppliance(ratePerKWh, calculatedWattage)
}
