<form class="mx-3 mb-0" #$form="ngForm" (ngSubmit)="add($form)" novalidate>
  <div class="row align-items-center" *ngIf="!light">
    <div class="col-md-6 form-group my-3 mb-0">
      <div class="row align-items-center">
        <div class="col-lg-3">
          <label for="count" class="mb-0">I have</label>
        </div>
        <div class="col-4">
          <input
            type="number"
            name="count"
            id="count"
            class="form-control text-default text-center"
            [min]="0"
            [(ngModel)]="count"
            (click)="select($event.target)"
            required
          />
        </div>
        <div class="col-6 col-lg-5">
          <div
            class="touch-controls touch-controls-no-active [ d-flex justify-content-center align-items-stretch ]"
          >
            <button
              type="button"
              class="btn btn-control"
              (click)="decreaseCount()"
            >
              <i
                class="edf-icon edf-icon-2x edf-icon-fixed-line-height edf-icon-decrease"
              ></i>
            </button>
            <button
              type="button"
              class="btn btn-control"
              (click)="increaseCount()"
            >
              <i
                class="edf-icon edf-icon-2x edf-icon-fixed-line-height edf-icon-increase"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 form-check my-3" *ngIf="extended">
      <div class="row align-items-center">
        <div class="col-4">
          <label for="alwaysOn" class="mb-0">always on</label>
        </div>
        <div class="col-6">
          <input
            type="checkbox"
            name="alwaysOn"
            id="alwaysOn"
            class="checkbox-control"
            [(ngModel)]="alwaysOn"
          />
          <label for="alwaysOn"></label>
        </div>
      </div>
    </div>

    <div class="col-md-6 form-check my-3" *ngIf="variableWattage">
      <div class="row align-items-center">
        <div class="col-lg-3">
          <label class="mb-0">Wattage</label>
        </div>
        <div class="col-lg-6 col-md-9 col-sm-6 col-6">
          <select
            class="form-control text-default"
            id="wattage"
            required
            name="watts"
            [(ngModel)]="watts"
          >
            <option *ngFor="let pow of variableWattage" [value]="pow">
              {{ pow }}W
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-md-6 form-check my-3" *ngIf="variableSquareMeter">
      <div class="row align-items-center">
        <div class="col-lg-3">
          <label class="mb-0">Square meters</label>
        </div>
        <div class="col-lg-6 col-md-9 col-sm-6 col-6">
          <select
            class="form-control text-default"
            id="squareMeters"
            required
            name="squareMeters"
            [(ngModel)]="squareMeters"
          >
            <option *ngFor="let pow of variableSquareMeter" [value]="pow">
              {{ pow }}m&#178;
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="row align-items-center" *ngIf="monthPerYear">
    <div class="col-md-6 form-group my-3">
      <div class="row align-items-center">
        <div class="col-lg-3">
          <label for="usage" class="mb-0">each used for</label>
        </div>
        <div class="col-4">
          <input
            type="number"
            name="monthPerYear"
            id="monthPerYear"
            class="form-control text-default text-center"
            [min]="1"
            [max]="12"
            [(ngModel)]="monthPerYear"
            (click)="select($event.target)"
            required
          />
        </div>
        <div class="col-6 col-lg-5">
          <label class="mb-0">months per year</label>
        </div>
      </div>
    </div>
  </div>
  <div class="row align-items-center" *ngIf="!light">
    <div
      class="col-md-6 form-group my-3"
      *ngIf="!alwaysOn"
      [ngSwitch]="abstracted"
    >
      <div class="row align-items-center" *ngSwitchCase="true">
        <div class="col-lg-3">
          <label for="usage" class="mb-0">each used</label>
        </div>
        <div class="col-4">
          <input
            type="number"
            name="usage"
            id="usage"
            class="form-control text-default text-center"
            [min]="0"
            [(ngModel)]="appliance.usage"
            (click)="select($event.target)"
            required
          />
        </div>
        <div class="col-6 col-lg-5">
          <label class="mb-0">time(s)</label>
        </div>
      </div>
      <div class="row align-items-center" *ngSwitchDefault>
        <div class="col-lg-3">
          <label for="usage" class="mb-0">each used for</label>
        </div>
        <div class="col-4">
          <input
            type="number"
            name="usage"
            id="usage"
            class="form-control display-4 text-default text-center"
            [min]="0"
            [max]="usageMetricMax"
            [(ngModel)]="usage"
            (click)="select($event.target)"
            required
          />
        </div>
        <div class="col-6 col-lg-5">
          <div
            class="touch-controls [ d-flex justify-content-center align-items-stretch ]"
          >
            <button
              type="button"
              class="btn btn-control"
              *ngFor="let metric of usageMetrics"
              [ngClass]="{ active: metric.active }"
              (click)="changeUsageMetric(metric.value)"
            >
              {{ metric.name }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 form-group my-3" *ngIf="!alwaysOn && !monthPerYear">
      <div class="row align-items-center">
        <div class="col-lg-3">
          <label class="mb-0">every</label>
        </div>
        <div class="col-lg-9">
          <div
            class="touch-controls [ d-flex justify-content-center align-items-stretch ]"
          >
            <button
              type="button"
              class="btn btn-control"
              *ngFor="let frequency of frequencies"
              [ngClass]="{ active: frequency.active }"
              (click)="changeFrequency(frequency.value)"
            >
              {{ frequency.name }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 form-group my-3" *ngIf="!alwaysOn && monthPerYear">
      <div class="row align-items-center">
        <div class="col-lg-3">
          <label class="mb-0">every day</label>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="light">
    <input type="hidden" name="count" id="count" [(ngModel)]="count" />
    <input type="hidden" name="usage" id="usage" [(ngModel)]="usage" />
  </div>
  <div class="row">
    <div *ngIf="added" class="col-6">
      <button
        type="button"
        class="btn btn-block btn-default"
        (click)="delete($event, $form)"
      >
        Remove
      </button>
    </div>
    <div class="col-6" [ngClass]="{ 'col-12': !added }">
      <button
        type="submit"
        class="btn btn-block btn-secondary"
        [disabled]="!$form.valid"
      >
        <span *ngIf="added">Update</span>
        <span *ngIf="!added">Add this</span>
      </button>
    </div>
  </div>
</form>
