import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usage'
})
export class UsagePipe implements PipeTransform {

  transform(usage: number, abstracted?: boolean): string {
    let value = usage;
    let unit = 'time';
    let plural;

    if (!abstracted) {
      const minutes = usage / 60;
      const hours = minutes / 60;

      value = minutes < 60 ? minutes : hours;
      unit = value === minutes ? 'min' : 'hour';
    }

    plural = value === 1 ? '' : 's';
    return `${value} ${unit}${plural}`;
  }

}
