<article class="full-height d-flex flex-column align-items bg-inverse">
  <section class="container scrollable">
    <a
      routerLink="../calculation"
      [queryParams]="{ billingCycle: billingCycle }"
      class="text-white"
      ><i class="edf-icon edf-icon-3x edf-icon-close"></i
    ></a>
    <h1 class="display-1 text-center text-white mt-3">Indicative total cost</h1>
    <p class="text-white mt-5 mx-3">
      The <strong><em>Indicative total cost</em></strong> also includes an
      estimated EDF standing charge and an indicative VAT value. VAT on energy
      adds 5% to your final bill.
    </p>
    <p class="text-white mt-3 mx-3">
      For your calculation, we’ve used the following figures:
    </p>
    <dl class="d-flex justify-content-between text-white mt-5 mx-3">
      <dt class="font-weight-normal">
        Estimated Standing Charge,<br />per {{ billingCycle }}
      </dt>
      <dd class="text-right">
        {{ totalCostStandingCharge | currency : "GBP" : true }}
      </dd>
    </dl>
    <dl class="d-flex justify-content-between text-white mt-3 mx-3">
      <dt class="font-weight-normal">
        Indicative VAT &#64; {{ vat | percent }},<br />per {{ billingCycle }}
      </dt>
      <dd class="text-right">{{ totalCostVAT | currency : "GBP" : true }}</dd>
    </dl>
  </section>
</article>
