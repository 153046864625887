import { keys, sortBy, groupBy, mapValues } from "lodash";
import {
  Component,
  OnInit,
  ElementRef,
  Renderer2,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { WindowService } from "../../services/window.service";
import { ApplianceComponent } from "../appliance/appliance.component";

@Component({
  selector: "app-appliance-list",
  templateUrl: "./appliance-list.component.html",
  styleUrls: ["./appliance-list.component.scss"],
})
export class ApplianceListComponent implements OnInit {
  categories: string[];
  appliances: any;
  _window: Window;

  @ViewChildren(ApplianceComponent) rendererAppliances: QueryList<
    ApplianceComponent
  >;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private windowService: WindowService
  ) {
    this._window = windowService.nativeWindow;
  }

  _showModal() {
    const body = this.el.nativeElement.firstChild;
    const modal = body.children.modal;

    // Create modal backdrop
    const backdrop = this.renderer.createElement("aside");
    this.renderer.addClass(backdrop, "modal-backdrop");
    this.renderer.addClass(backdrop, "fade");

    // Prepare modal elements
    this.renderer.addClass(body, "modal-open");
    this.renderer.insertBefore(body, backdrop, modal);
    this.renderer.setStyle(modal, "display", "block");

    setTimeout(() => {
      // Add animation classes
      this.renderer.addClass(modal, "show");
      this.renderer.addClass(backdrop, "show");
    });
  }

  ngOnInit() {
    this.route.data.subscribe(({ appliances }) => {
      // Cache resolved appliances organised into categories
      this.appliances = mapValues(groupBy(appliances, "category"), (category) =>
        sortBy(category, "sortOrder")
      );

      // Cache categories for reference
      this.categories = keys(this.appliances);
    });
    window.scrollTo(0, 0);
  }

  onAskRemovalOfLightsSiblings(id: string) {
    const rendererLightAppliances = this.rendererAppliances.filter(
      (appliance) => appliance.light === true
    );
    const appliancesToRemove = rendererLightAppliances.filter(
      (appliance) => appliance.id !== id
    );
    for (let index = 0; index < appliancesToRemove.length; index++) {
      appliancesToRemove[index].remove();
    }
  }

  startCalculating() {
    this._showModal();
  }
}
