import { get, isFunction } from "lodash";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgForm } from "@angular/forms";
import { BillingCycle } from "../../services/configuration.service";

export enum UsageMetric {
  minutes,
  hours,
}

@Component({
  selector: "app-appliance-detail",
  templateUrl: "./appliance-detail.component.html",
  styleUrls: ["./appliance-detail.component.scss"],
})
export class ApplianceDetailComponent implements OnInit {
  @Output() onSubmit: EventEmitter<any> = new EventEmitter();
  @Input() appliance: any;
  @Input() abstracted: boolean;
  @Input() extended: boolean;
  @Input() added: boolean;
  @Input() light: boolean;
  @Input() variableWattage: number[];
  @Input() variableSquareMeter: number[];

  count: number;
  alwaysOn: boolean;

  watts: number;
  squareMeters: number;
  monthPerYear: number;

  usage: number;
  usageMetric: string;
  usageMetricMax: number;
  usageMetrics = [
    { name: "Mins", value: UsageMetric.minutes, max: 100, active: false },
    { name: "Hours", value: UsageMetric.hours, max: 24, active: false },
  ];

  frequency: string;
  frequencies = [
    { name: "Day", value: BillingCycle.day, active: false },
    { name: "Week", value: BillingCycle.week, active: false },
    { name: "Month", value: BillingCycle.month, active: false },
    { name: "Year", value: BillingCycle.year, active: false },
  ];

  constructor() {}

  _setUsageMetricMax(usageMetrics): void {
    const activeUsageMetric = usageMetrics.find(({ active }) => active);
    this.usageMetricMax = get(activeUsageMetric, "max");
  }

  _setActiveUsageMetric(value): void {
    this.usageMetric = UsageMetric[value];
    this.usageMetrics = this.usageMetrics.map((usageMetric) => {
      usageMetric.active = usageMetric.value === value;
      return usageMetric;
    });
    this._setUsageMetricMax(this.usageMetrics);
  }

  _setActiveFrequency(value): void {
    this.frequency = BillingCycle[value];
    this.frequencies = this.frequencies.map((frequency) => {
      frequency.active = frequency.value === value;
      return frequency;
    });
  }

  _submit(form: NgForm) {
    this.onSubmit.emit(
      simplify(
        form.value,
        this.usageMetric,
        this.frequency,
        this.alwaysOn,
        this.abstracted
      )
    );
  }

  ngOnInit() {
    this.watts = this.appliance.watts;
    this.squareMeters = this.appliance.squareMeters;
    this.monthPerYear = this.appliance.monthPerYear;

    this.count =
      this.appliance && this.appliance.count > 0 ? this.appliance.count : 1;

    if (this.extended) {
      this.alwaysOn = isAlwaysOn(
        convertUsageMetric(
          this.appliance.usage,
          UsageMetric[UsageMetric.hours],
          -1
        ),
        this.appliance.frequency
      );
    }

    if (!this.abstracted) {
      const usageMetric = getUsageMetric(this.appliance.usage);

      this.usage = convertUsageMetric(this.appliance.usage, usageMetric, -1);
      this._setActiveUsageMetric(UsageMetric[usageMetric]);
    }

    this._setActiveFrequency(BillingCycle[this.appliance.frequency]);
  }

  select(target) {
    isFunction(target.select) && target.select();
  }

  increaseCount() {
    this.count++;
  }

  decreaseCount() {
    this.count > 0 && this.count--;
  }

  changeUsageMetric(value) {
    this._setActiveUsageMetric(value);
  }

  changeFrequency(value) {
    this._setActiveFrequency(value);
  }

  add(form: NgForm) {
    if (!form.valid) {
      return;
    }
    this._submit(form);
  }

  delete($event, form: NgForm) {
    $event.stopPropagation();

    // Set count to 0 and submit,
    // this produces expected outcome
    form.value.count = 0;
    this._submit(form);
  }
}

function simplify(
  { count, usage, watts, monthPerYear, squareMeters }: any,
  usageMetric: string,
  frequency: string,
  alwaysOn: boolean,
  abstracted: boolean
) {
  if (alwaysOn) {
    usage = 24;
    usageMetric = UsageMetric[UsageMetric.hours];
    frequency = BillingCycle[BillingCycle.day];
  }

  if (!abstracted) {
    usage = convertUsageMetric(usage, usageMetric);
  }

  return { count, usage, frequency, watts, monthPerYear, squareMeters };
}

function isAlwaysOn(usage: number, frequency: string): boolean {
  let days: number;

  switch (BillingCycle[frequency]) {
    case BillingCycle.day:
      days = 1;
      break;

    case BillingCycle.week:
      days = 7;
      break;

    case BillingCycle.month:
      days = 30;
      break;

    case BillingCycle.year:
      days = 365;
      break;

    default:
      days = 1;
  }

  return 24 * days === usage * days;
}

function getUsageMetric(seconds: number) {
  const minutes = seconds / 60;
  const hours = minutes / 60;

  return UsageMetric[minutes <= 100 ? UsageMetric.minutes : UsageMetric.hours];
}

function convertUsageMetric(
  usage: number,
  usageMetric: string,
  factor: number = 1
): number {
  const exponent = UsageMetric[usageMetric] === UsageMetric.hours ? 2 : 1;
  return usage * Math.pow(60, exponent * factor);
}
