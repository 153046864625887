import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "seasonalUsage",
})
export class SeasonalUsagePipe implements PipeTransform {
  transform(monthPerYear: number): unknown {
    const plural = monthPerYear === 1 ? "" : "s";

    return `${monthPerYear} month${plural}`;
  }
}
