import { Component, ElementRef, AfterContentInit } from "@angular/core";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { Router, ActivatedRoute } from "@angular/router";

// Global declarations
declare const window: Window;
interface Window {
  jQuery: any;
}

@Component({
  selector: "app-onboarding",
  templateUrl: "./onboarding.component.html",
  styleUrls: ["./onboarding.component.scss"],
  animations: [
    trigger("fade", [
      transition("void => *", [
        style({ opacity: 0 }),
        animate("0.3s 0.3s ease-in", style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class OnboardingComponent implements AfterContentInit {
  $carousel: any;
  slideIndex = 0;

  constructor(
    private el: ElementRef,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngAfterContentInit() {
    const $root = window.jQuery(this.el.nativeElement);
    const $carousel = $root.find("#carousel");
    const $dots = $root.find("#dots");

    // Configure carousel
    const config = {
      dots: true,
      arrows: false,
      infinite: false,
      mobileFirst: true,
      appendDots: $dots,
    };

    // Create carousel
    this.$carousel = $carousel.slick(config);
    this.slideIndex = this.$carousel.slick("slickCurrentSlide");
    console.log(this.slideIndex);
  }

  prev() {
    this.slideIndex = this.$carousel.slick("slickCurrentSlide");

    if (this.slideIndex > 0) {
      this.$carousel.slick("slickPrev");
      this.slideIndex--;
      return;
    }
  }

  next() {
    const slideCount = this.$carousel[0].slick.slideCount;
    this.slideIndex = this.$carousel.slick("slickCurrentSlide");

    if (this.slideIndex < slideCount - 1) {
      this.$carousel.slick("slickNext");
      this.slideIndex++;
      return;
    }

    console.log(this.route);
    this.router.navigate(["../appliances"], { relativeTo: this.route });
  }
}
