import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { sortBy } from "lodash";
import { ProfileService } from "../../services/profile.service";
import { WindowService } from "../../services/window.service";

import {
  ApplianceCalculation,
  BillingCycle,
  Calculation,
  ConfigurationService,
} from "../../services/configuration.service";

@Component({
  selector: "app-calculation",
  templateUrl: "./calculation.component.html",
  styleUrls: ["./calculation.component.scss"],
})
export class CalculationComponent implements OnInit {
  totalApplianceCount: number;
  totalCost: number;
  totalKWH: number;
  allAppliances: ApplianceCalculation[];
  billingCycle: string;
  billingCycles = [{ name: "Yearly", value: BillingCycle.year, active: false }];
  resultSubmitted: boolean;
  _window: Window;
  lastBillTariff: number;

  constructor(
    private route: ActivatedRoute,
    private configurationService: ConfigurationService,
    private profileService: ProfileService,
    private windowService: WindowService,
    private router: Router
  ) {
    this._window = windowService.nativeWindow;
  }

  _setActiveBillingCycle(value): void {
    this.billingCycle = BillingCycle[value];
    this.billingCycles = this.billingCycles.map((billingCycle) => {
      billingCycle.active = billingCycle.value === value;
      return billingCycle;
    });
  }

  _setAppliancesGraphing(appliances: ApplianceCalculation[]): void {
    if (!appliances.length) {
      return;
    }

    let sortedData = sortBy(appliances, ["cost", "usage"]).reverse();
    const highestCost = sortedData[0].cost;

    sortedData = sortedData.map((appliance) => {
      appliance.relativeCost = (appliance.cost / highestCost) * 100;
      return appliance;
    });

    this.allAppliances = sortedData;
  }

  _setResultSubmitted(): void {
    this.profileService
      .getResultSubmitted()
      .then((value: boolean) => {
        this.resultSubmitted = value;
      })
      .catch((reason: any) => {
        console.log(reason);
      });
  }

  _setLastBillTarrif(): void {
    this.profileService
      .getLastBillUnitRate()
      .then((value: number) => {
        this.lastBillTariff = value;
      })
      .catch((reason: any) => {
        this.lastBillTariff = 0.0;
      });
  }

  _updateModel(calculation: Calculation): void {
    this.totalApplianceCount = calculation.count;
    this.totalCost = calculation.costIncVAT;
    this.totalKWH = calculation.usage;

    this._setActiveBillingCycle(calculation.billingCycle);
    this._setAppliancesGraphing(calculation.appliances);
    this._setResultSubmitted();
    this._setLastBillTarrif();
  }

  ngOnInit() {
    this.route.data.subscribe(({ calculation }) => {
      this._updateModel(calculation);
    });
    window.scrollTo(0, 0);
  }
}
