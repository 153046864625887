import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Profile } from '../../services/profile.service';
import { BillingCycle, Calculation } from '../../services/configuration.service';

@Component({
  selector: 'app-explainer',
  templateUrl: './explainer.component.html',
  styleUrls: ['./explainer.component.scss']
})
export class ExplainerComponent implements OnInit {
  billingCycle: string;
  totalCostStandingCharge: number;
  totalCostVAT: number;
  vat: number;

  constructor(private route: ActivatedRoute) {}

  _updateModel(vat: number, calculation: Calculation): void {
    this.vat = vat;

    this.billingCycle = BillingCycle[calculation.billingCycle];
    this.totalCostStandingCharge = calculation.costStandingCharge;
    this.totalCostVAT = calculation.costVAT;
  }

  ngOnInit() {
    this.route.data
      .subscribe(({ vat, calculation }) => {
        this._updateModel(vat, calculation);
      });
  }

}
