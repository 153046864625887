import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
})
export class LandingComponent implements OnInit {
  getStartedUrl = "intro";
  showLogo = true;

  constructor(private router: Router) {}

  ngOnInit(): void {
    // Destroy pre-existing splash screen EDF logo
    const splashScreenLogo = document.getElementById("edf");
    splashScreenLogo && splashScreenLogo.remove();

    this.showLogo = this.router.url !== "/shs";

    // Determine journey type
    if (this.router.url === "/csa") {
      // Skip the onboarding
      this.getStartedUrl = "data-capture/electricity-charge";
    }
  }
}
