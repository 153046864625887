import { RouterModule } from "@angular/router";

// Resolvers
import { AppliancesResolver } from "./resolvers/appliances.resolver";
import { CalculationResolver } from "./resolvers/calculation.resolver";
import { TariffResolver } from "./resolvers/tariff.resolver";
import { VATResolver } from "./resolvers/vat.resolver";
import { LastBillResolver } from "./resolvers/last-bill.resolver";

// Components
import {
  ApplianceListComponent,
  CalculationComponent,
  ExplainerComponent,
  TariffComponent,
  LandingComponent,
  OnboardingComponent,
  IntroComponent,
  ElectricityChargeComponent,
  ElectricityUnitComponent,
  ElectricityPpkComponent,
  ElectricityPeriodComponent,
  ElectricityConfirmComponent,
  SubmitResultThankyou,
  WrapperComponent,
} from "./components/";

const customerOnly: any[] = [{ path: "intro", component: OnboardingComponent }];

const dataCapture: any[] = [
  {
    path: "data-capture/intro",
    component: IntroComponent,
  },
  {
    path: "data-capture/electricity-charge",
    component: ElectricityChargeComponent,
    resolve: { lastBill: LastBillResolver },
  },
  {
    path: "data-capture/electricity-unit",
    component: ElectricityUnitComponent,
    resolve: { lastBill: LastBillResolver },
  },
  {
    path: "data-capture/electricity-ppk",
    component: ElectricityPpkComponent,
    resolve: { lastBill: LastBillResolver },
  },
  {
    path: "data-capture/electricity-period",
    component: ElectricityPeriodComponent,
    resolve: { lastBill: LastBillResolver },
  },
  {
    path: "data-capture/electricity-confirm",
    component: ElectricityConfirmComponent,
    resolve: { lastBill: LastBillResolver },
  },
];

const commonRoutes = [
  { path: "", component: LandingComponent },
  {
    path: "appliances",
    component: ApplianceListComponent,
    resolve: { appliances: AppliancesResolver },
  },
  {
    path: "calculation",
    component: CalculationComponent,
    resolve: { calculation: CalculationResolver },
  },
  {
    path: "explainer",
    component: ExplainerComponent,
    resolve: { vat: VATResolver, calculation: CalculationResolver },
  },
  {
    path: "tariff",
    component: TariffComponent,
    resolve: { unitPrice: TariffResolver },
  },
  {
    path: "submit-result-thankyou",
    component: SubmitResultThankyou,
  },
];

const customerJourney = commonRoutes.concat(customerOnly);
const csaJourney = commonRoutes.concat(dataCapture);

const routes = [
  {
    path: "",
    component: WrapperComponent,
    children: customerJourney,
  },
  {
    path: "shs",
    component: WrapperComponent,
    children: customerJourney,
  },
  {
    path: "csa",
    component: WrapperComponent,
    children: csaJourney,
  },
];

export const AppRouter = RouterModule.forRoot(routes, { useHash: false });
