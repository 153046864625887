<article
  class="full-height scrollable d-flex flex-column align-items bg-inverse"
>
  <header class="text-center bg-primary">
    <div
      class="edf-logo"
      aria-label="EDF Logo"
      [inlineSVG]="'/assets/svg/edf.svg'"
    ></div>
  </header>
  <section id="carousel" [@fade] class="carousel mt-3">
    <div>
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6">
            <div
              class="carousel-vector-responsive mx-3"
              aria-label="Step 1"
              [inlineSVG]="'/assets/svg/onboarding/step1.svg'"
            ></div>
          </div>
          <div class="col-12 col-md-6 text-center text-md-left">
            <p class="step-title [ lead text-white ]">
              Step <strong>1</strong> of 2
            </p>
            <h1 class="carousel__header display-1 text-white mb-4">
              Add your electrical appliances from our list
            </h1>
            <p class="text-white mb-0">It’s as easy as tap tap tap!</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Temporarily disable step two for this release (20-4-2020) -->
    <!-- <div>
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="carousel-vector-responsive mx-3" aria-label="Step 2" [inlineSVG]="'/assets/svg/onboarding/step2.svg'"></div>
          </div>
          <div class="col-12 col-md-6 text-center text-md-left">
            <p class="step-title [ lead text-white mt-3 ]">Step <strong>2</strong> of 3</p>
            <h1 class="carousel__header display-1 text-white mb-4">Add some optional usage details</h1>
            <p class="text-white mb-0">We just need rough numbers for now, you can always adjust them later</p>
          </div>
        </div>
      </div>
    </div> -->
    <div>
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6">
            <div
              class="carousel-vector-responsive mx-3"
              aria-label="Step 2"
              [inlineSVG]="'/assets/svg/onboarding/step3.svg'"
            ></div>
          </div>
          <div class="col-12 col-md-6 text-center text-md-left">
            <p class="step-title [ lead text-white mt-3 ]">
              Step <strong>2</strong> of 2
            </p>
            <h1 class="carousel__header display-1 text-white mb-4">
              See how much your appliances cost to run
            </h1>
            <p class="text-white mb-0">
              See all your appliances broken down and an estimated total
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="btn-wrapper">
    <div class="d-flex">
      <button
        class="[ btn btn-lg btn-baseline btn-secondary ] w100 text-white dc-previous"
        (click)="prev()"
        *ngIf="slideIndex > 0"
      >
        <i class="edf-icon edf-icon-chevron-left"></i>
        <span>Back</span>
      </button>
      <button
        class="[ btn btn-lg btn-baseline btn-secondary ] w100 dc-next"
        (click)="next()"
      >
        <span>Next</span>
        <i class="edf-icon edf-icon-chevron-right"></i>
      </button>
    </div>
  </section>
</article>
