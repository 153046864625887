import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { ProfileService } from '../services/profile.service';

@Injectable()
export class TariffResolver implements Resolve<number> {

  constructor(
    private profileService: ProfileService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<number> {
    return this.profileService.getProfileTariff();
  }

}
