import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Appliance, ConfigurationService } from '../services/configuration.service';

@Injectable()
export class AppliancesResolver implements Resolve<Appliance[]> {

  constructor(private configurationService: ConfigurationService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Appliance[]> {
    return this.configurationService.getAppliances();
  }

}
