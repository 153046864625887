import { isFunction } from "lodash";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ProfileService } from "../../../services/profile.service";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-electricity-ppk",
  templateUrl: "./electricity-ppk.component.html",
  styleUrls: ["./electricity-ppk.component.scss"],
})
export class ElectricityPpkComponent implements OnInit {
  lastBillUnitRate: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private profileService: ProfileService
  ) {}

  ngOnInit() {
    this.route.data.subscribe(({ lastBill }) => {
      this.lastBillUnitRate = lastBill.lastBillUnitRate;
    });
  }

  previous() {
    this.router.navigate(["../../data-capture/electricity-unit"], {
      relativeTo: this.route,
    });
  }

  select(target) {
    isFunction(target.select) && target.select();
  }

  onSubmit(form: NgForm): void {
    if (!form.valid) {
      return;
    }

    const lastBillUnitRate = form.value.lastBillUnitRate;
    this.profileService
      .setLastBillUnitRate(lastBillUnitRate)
      .then(() => {
        if (lastBillUnitRate > 0) {
          this.profileService
            .setProfileTariff(lastBillUnitRate / 100)
            .then(() =>
              this.router.navigate(["../../data-capture/electricity-period"], {
                relativeTo: this.route,
              })
            )
            .catch((err) => console.error(err));
        } else {
          this.router.navigate(["../../data-capture/electricity-period"], {
            relativeTo: this.route,
          });
        }
      })
      .catch((err) => console.error(err));
  }
}
