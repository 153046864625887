/**
 *  Handle appliance energy consumption calculations
 *  @module appcalc
 *  @example import * as appcalc from 'appcalc'
 */

import forAirConditioning from './forAirConditioning'
import forOtherAppliance from './forOtherAppliance'
import forRefrigeration from './forRefrigeration'

export {
  forRefrigeration,
  forAirConditioning,
  forOtherAppliance,
}
