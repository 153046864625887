<article class="d-flex flex-column align-items bg-white">
  <form class="[ mb-0 full-height ] [ d-flex flex-column ] align-items" #$form="ngForm" (ngSubmit)="onSubmit($form)" novalidate>
    <header class="text-center bg-primary">
      <div class="edf-logo" aria-label="EDF Logo" [inlineSVG]="'/assets/svg/edf.svg'"></div>
    </header>
    <section class="scrollable">
      <header class="bg-muted mb-5">
        <div class="text-center text-md-left container [ py-3 py-md-5 ]">
          <h1 class="display-1">Total electricity charge (£)</h1>
          <p class="mt-3 mb-0">From your bill, please enter the value for “Total electricity charges for this period”</p>
        </div>
      </header>
      <div class="container">
        <section class="summary mb-3">
          <div class="summary__image">
            <img src="/assets/images/dc-ec-1.png" srcset="/assets/images/dc-ec-1@2x.png 2x, /assets/images/dc-ec-1@3x.png 3x" class="example-bill">
            <p class="note">Example bill</p>
          </div>
        </section>
      </div>
      <div class="container">
        <section class="d-flex mb-3">
          <span class="display-2 mr-2 mt-3 ">£</span>
          <input type="number" name="lastBillCharge" id="lastBillCharge" class="form-control display-2 text-default text-right charge" min="1" [(ngModel)]="lastBillCharge" (click)="select($event.target)" required/>
        </section>
      </div>
    </section>
    <section class="btn-wrapper">
      <button class="[ btn btn-lg btn-baseline btn-block btn-primary ]" [disabled]="!$form.valid" type="submit">Next</button>
    </section>
  </form>
</article>
