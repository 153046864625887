import { Component, Input } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-appliance-calculation',
  templateUrl: './appliance-calculation.component.html',
  styleUrls: ['./appliance-calculation.component.scss'],
  animations: [
    trigger('draw', [
      transition(':enter', [
        style({ width: 0 }),
        animate('1.5s ease-in-out', style({ width: '*' }))
      ])
    ])
  ]
})
export class ApplianceCalculationComponent {
  @Input() name: string;
  @Input() cost: number;
  @Input() usage: number;
  @Input() relativeCost: number;

}
