<article class="full-height d-flex flex-column align-content-stretch bg-white">
  <header class="text-center bg-primary">
    <div
      class="edf-logo"
      aria-label="EDF Logo"
      [inlineSVG]="'/assets/svg/edf.svg'"
    ></div>
  </header>
  <section>
    <header class="bg-muted mb-4">
      <div class="container [ pb-3 pb-md-4 ] [ pt-4 pt-md-5 ]">
        <h1 class="display-1 d-flex estimate">
          <span class="estimate__heading">
            Indicative Annual Cost
            <a
              routerLink="../explainer"
              class="explainer-link [ text-default ]"
              [queryParams]="{ billingCycle: billingCycle }"
            >
              <i class="edf-icon edf-icon-help"></i>
            </a>
          </span>
          <span class="estimate__value estimated-cost text-center px-1"
            >&pound;{{ totalCost | number : "1.0-0" }}</span
          >
        </h1>
        <p class="d-flex estimate [ mb-3 ]">
          <span class="estimate__heading">Estimated total electricity</span>
          <span class="estimate__value [ text-sm-center px-sm-1 ]"
            >{{ totalKWH | number : "1.0-0" }}kWh</span
          >
        </p>
        <p class="mb-0">
          Based on
          <a routerLink="../appliances" class="appliances-count-link"
            >{{ totalApplianceCount }} appliances</a
          >
        </p>
      </div>
    </header>
    <div class="container">
      <div class="bg-muted [ py-3 px-0 px-sm-3 ]">
        <div class="graph p-3 bg-white">
          <ol class="list-unstyled">
            <li
              class="my-3 pb-3 appliance-item"
              *ngFor="let appliance of allAppliances"
            >
              <app-appliance-calculation
                name="{{ appliance.name }}"
                cost="{{ appliance.cost }}"
                usage="{{ appliance.usage }}"
                relativeCost="{{ appliance.relativeCost }}"
              ></app-appliance-calculation>
            </li>
          </ol>
        </div>
        <div class="well [ mt-3 p-3 mx-3 mx-sm-0 ]">
          <p *ngIf="!lastBillTariff" class="mb-0">
            Our calculations are based on EDF’s Standard Variable Rate Tariff
            updated as of 01/04/2024. Rates used are for the South Eastern
            region.
          </p>
          <p *ngIf="!!lastBillTariff" class="mb-0">
            Our calculations are based on the tariff {{ lastBillTariff }}p
            entered in the beginning.
          </p>
        </div>
      </div>
    </div>
    <!--<div class="well [ my-3 p-3 ]">
      <p class="mb-0">Our calculations are based on an average of the EDF Energy Standard Variable Rate tariff. To make your calculation more accurate you can <a routerLink="/tariff">add your own tariff</a>.</p>
    </div>-->
  </section>

  <section class="btn-wrapper">
    <div class="d-flex">
      <a
        routerLink="../appliances"
        class="[ btn btn-lg btn-baseline btn-secondary ] w100 dc-previous"
        >Edit appliances</a
      >
    </div>
  </section>
</article>
