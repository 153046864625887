import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { InlineSVGModule } from "ng-inline-svg-2";

// App root
import { AppComponent } from "./app.component";
import { AppRouter } from "./app.routes";

// Services
import { ConfigurationService } from "./services/configuration.service";
import { ProfileService } from "./services/profile.service";
import { WindowService } from "./services/window.service";

// Resolvers
import { AppliancesResolver } from "./resolvers/appliances.resolver";
import { CalculationResolver } from "./resolvers/calculation.resolver";
import { LastBillResolver } from "./resolvers/last-bill.resolver";
import { TariffResolver } from "./resolvers/tariff.resolver";
import { VATResolver } from "./resolvers/vat.resolver";

// Pipes
import { KebabCasePipe } from "./pipes/kebab-case.pipe";
import { NoCommaPipe } from "./pipes/no-comma.pipe";
import { SeasonalUsagePipe } from "./pipes/seasonal-usage.pipe";
import { UsagePipe } from "./pipes/usage.pipe";

// Components
import {
  ApplianceCalculationComponent,
  ApplianceComponent,
  ApplianceDetailComponent,
  ApplianceListComponent,
  CalculationComponent,
  ElectricityChargeComponent,
  ElectricityConfirmComponent,
  ElectricityPeriodComponent,
  ElectricityPpkComponent,
  ElectricityUnitComponent,
  ExplainerComponent,
  IntroComponent,
  LandingComponent,
  OnboardingComponent,
  SubmitResultThankyou,
  TariffComponent,
  WrapperComponent,
} from "./components/";

@NgModule({
  declarations: [
    AppComponent,
    ApplianceComponent,
    ApplianceListComponent,
    ApplianceCalculationComponent,
    ApplianceDetailComponent,
    CalculationComponent,
    ExplainerComponent,
    TariffComponent,
    LandingComponent,
    OnboardingComponent,
    IntroComponent,
    KebabCasePipe,
    UsagePipe,
    ElectricityChargeComponent,
    ElectricityUnitComponent,
    ElectricityPpkComponent,
    ElectricityPeriodComponent,
    ElectricityConfirmComponent,
    WrapperComponent,
    SubmitResultThankyou,
    NoCommaPipe,
    SeasonalUsagePipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    InlineSVGModule,
    AppRouter,
  ],
  providers: [
    ConfigurationService,
    ProfileService,
    WindowService,
    AppliancesResolver,
    CalculationResolver,
    TariffResolver,
    VATResolver,
    LastBillResolver,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
