<article class="full-height align-items bg-inverse bg-white">
  <header class="text-center bg-primary">
    <div class="edf-logo" aria-label="EDF Logo" [inlineSVG]="'/assets/svg/edf.svg'"></div>
  </header>
  <section>
    <header class="bg-muted mb-5">
      <div class="text-center text-md-left container [ py-3 py-md-5 ]">
        <h1 class="display-1 mb-3">Thank you!</h1>
        <p class="mb-3">Thank you for taking the time to complete this beta trial, the information you supplied is saved anonymously and securely.</p>
        <p class="mb-3">Thanks again!<br/>EDF Energy</p>
      </div>
    </header>
  </section>
  <section class="btn-wrapper">
    <button class="btn btn-lg btn-baseline btn-block btn-primary" (click)="backToResults()" type="button">
      Back to your results
    </button>
  </section>
</article>
